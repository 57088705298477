<template>
  <div class="affixion-contain">
    <Form :label-width="115">
      <Row>
        <Col span="8" offset="8">
          <Form-item label="日期：">
            <Date-picker type="date" :options="options" v-model="test_date" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Form-item>
          <Form-item label="采集时间：">
            <Time-picker confirm type="time" placeholder="选择时间" v-model="test_time" @on-change="handleChangeTime"></Time-picker>
          </Form-item>
          <Form-item label="身高：">
            <Row>
              <Col :span="22"><Input v-model="heightData.height" placeholder="请输入身高"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;cm</Col>
            </Row>
          </Form-item>
          <Form-item label="臀围：">
            <Row>
              <Col :span="22"><Input v-model="heightData.hip_line" placeholder="请输入臀围"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;cm</Col>
            </Row>
          </Form-item>
          <Form-item label="腰围：">
            <Row>
              <Col :span="22"><Input v-model="heightData.wigst" placeholder="请输入腰围"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;cm</Col>
            </Row>
          </Form-item>
          <Form-item label="腰臀比：">
            <Row>
              <Col :span="22"><Input v-model="heightData.whr" placeholder="请输入腰臀比"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;</Col>
            </Row>
          </Form-item>
        </Col>
      </Row>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			heightData: {
				test_date: '',
				test_time: '',
				height: '',
				wigst: '',
				hip_line: '',
				whr: '',
			},
			test_date: '',
			test_time: '',
			power: [],
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		heightId() {
			return this.$route.params.heightId;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	methods: {
		handleInit() {
			let arr = [];
			MemberService.heightDetail({ id: this.heightId }).then((data) => {
				arr = data.create_time.split(' ');
				this.test_date = this.heightData.test_date = arr[0];
				this.test_time = this.heightData.test_time = arr[1];
				this.heightData.wigst = data.wastline === 0 ? '' : data.wastline;
				this.heightData.height = data.height === 0 ? '' : data.height;
				this.heightData.hip_line = data.hip_line === 0 ? '' : data.hip_line;
				this.heightData.whr = data.whr === 0 ? '' : data.whr;
			});
		},
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.heightData.id = this.heightId;
				MemberService.heightEdit(this.heightData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.heightData.test_date = value;
		},
		handleChangeTime(value) {
			this.heightData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.test_date) {
				this.$Message.warning('请选择日期');
				return false;
			} else if (!this.test_time) {
				this.$Message.warning('请选择采集时间');
				return false;
			} else {
				return true;
			}
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
